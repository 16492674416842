import React from 'react';
import PropTypes from 'prop-types';
import {useDroppable} from '@dnd-kit/core';
import {get, find, isEmpty} from 'lodash';
import {processStringContent} from "utils/content";

import AnswerOption from "./AnswerOption";


function AnswerLayoutDroppable({is_preview, answer_groups, onRemove, part}) {
    const {isOver, setNodeRef} = useDroppable({
        id: part.dnd_id,
    });

    const answer_group = find(answer_groups, {dnd_type: part.dnd_type});

    function getDroppableStyle(answer_group, part) {
        const color_light = get(answer_group, 'color_light')
        const color_dark = get(answer_group, 'color_dark')
        return {
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            minWidth: "100px",
            minHeight: "100px",
            padding: isOver ? "10px" : "10px",
            background: color_light,
            borderWidth: "2px",
            borderStyle: "dashed",
            borderColor: color_dark,
            borderRadius: "4px",
            position: "relative",
            ...get(part, 'droppable_style')
        }
    }

    function renderPlaceholder(part) {
        if (isEmpty(part.content)) {
            return (
                <div className="dnd-answer-placeholder flex-row flex-vh-center">
                    {processStringContent(part.placeholder)}
                </div>
            );
        } else {
            return null;
        }
    }

    function renderSelected(part, answer_group) {
        if (isEmpty(part.content)) {
            return null;
        }

        const extra_style = {}

        return (
            <AnswerOption
                key={part.content.dnd_id}
                is_answer={true}
                is_preview={is_preview}
                answer_group={answer_group}
                option={part.content}
                option_index={0}
                extra_style={extra_style}
                onRemove={() => onRemove(part.dnd_id)}
                being_dragged={false}
            />
        );
    }

    return (
        <div ref={setNodeRef} style={getDroppableStyle(answer_group, part)}>
            {renderPlaceholder(part)}

            {renderSelected(part, answer_group)}
        </div>
    );
}

AnswerLayoutDroppable.propTypes = {
    is_preview: PropTypes.bool.isRequired,
    answer_groups: PropTypes.array.isRequired,
    onRemove: PropTypes.func.isRequired,
    part: PropTypes.shape({
        dnd_id: PropTypes.string.isRequired,
        dnd_type: PropTypes.string.isRequired,
    }).isRequired,
}

export default AnswerLayoutDroppable
