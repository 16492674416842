import React from 'react';
import PropTypes from 'prop-types';
import {get} from 'lodash';

import {processStringContent} from 'utils/content';


function DnDDraggedItem(
    {
        is_preview,
        item,
        onRemove = () => {
        }
    }
) {
    function getDraggableStyle(default_style) {
        return {
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            margin: "0",
            minHeight: "50px",
            minWidth: "50px",
            borderRadius: "4px",
            ...get(item, 'draggable_style'),
            ...default_style
        }
    }

    function getContent() {
        const contentImage = get(item, 'content.image');
        const contentHtml = get(item, 'content.html');
        if (contentImage) {
            return (
                <img src={contentImage} alt={'DND option'}/>
            )
        } else if (contentHtml) {
            return processStringContent(contentHtml);
        } else {
            return null;
        }
    }

    const showRemoveIcon = () => {
        if (!is_preview) {
            return (
                <i
                    className="dnd-answer-one-remove far fa-times-circle"
                    onClick={onRemove}
                >
                </i>
            );
        }
    }

    return (
        <div
            className="dnd-option-selected"
            style={getDraggableStyle({})}
        >
            {showRemoveIcon()}

            {getContent()}
        </div>
    )
}

DnDDraggedItem.propTypes = {
    is_preview: PropTypes.bool.isRequired,
    item: PropTypes.shape({
        dnd_id: PropTypes.string.isRequired,
        content: PropTypes.shape({
            image: PropTypes.string,
            html: PropTypes.string
        }).isRequired,
    }).isRequired,
    onRemove: PropTypes.func
}

export default DnDDraggedItem
