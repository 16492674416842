import React from 'react';
import PropTypes from 'prop-types';
import {useDraggable} from '@dnd-kit/core';
import {get} from 'lodash';

import {processStringContent} from 'utils/content';


function DnDDraggableItem(
    {
        is_preview,
        item
    }
) {
    const {attributes, listeners, setNodeRef, transform} = useDraggable({
        id: item.dnd_id,
        data: {type: '123'},
        disabled: is_preview
    });

    function getDraggableStyle(default_style) {
        return {
            transform: transform ? `translate3d(${transform.x}px, ${transform.y}px, 0)` : undefined,
            cursor: !is_preview ? "grab" : 'default',
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            margin: "0",
            minHeight: "50px",
            minWidth: "50px",
            borderRadius: "4px",
            position: "relative",
            zIndex: 1,
            ...get(item, 'draggable_style'),
            ...default_style
        }
    }

    function getContent() {
        const contentImage = get(item, 'content.image');
        const contentHtml = get(item, 'content.html');
        if (contentImage) {
            return (
                <img src={contentImage} alt={'DND option'}/>
            )
        } else if (contentHtml) {
            return processStringContent(contentHtml);
        } else {
            return null;
        }
    }

    return (
        <div
            ref={setNodeRef}
            className="dnd-option"
            style={getDraggableStyle()}
            {...listeners}
            {...attributes}
        >
            {getContent()}
        </div>
    );
}

DnDDraggableItem.propTypes = {
    is_preview: PropTypes.bool.isRequired,
    item: PropTypes.shape({
        dnd_id: PropTypes.string.isRequired,
        content: PropTypes.shape({
            image: PropTypes.string,
            html: PropTypes.string
        }).isRequired,
    }).isRequired
}

export default DnDDraggableItem
