import React, {memo} from 'react';
import PropTypes from 'prop-types';
import {useDraggable} from '@dnd-kit/core';
import {get} from 'lodash';

import {processStringContent} from 'utils/content';

function AnswerOption(
    {
        // is_answer = true if this is for answer, is_answer = false for option
        is_answer,
        is_preview,
        answer_group,
        option,
        extra_style,
        onRemove = () => {
        },
        being_dragged
    }
) {
    const {attributes, listeners, setNodeRef, transform} = useDraggable({
        id: option.dnd_id,
        data: {type: answer_group.dnd_type},
        disabled: is_preview
    });

    function getDraggableStyle() {
        const color_light = get(answer_group, 'color_light')
        const color_dark = get(answer_group, 'color_dark')
        return {
            transform: transform ? `translate3d(${transform.x}px, ${transform.y}px, 0)` : undefined,
            display: "flex",
            alignItems: "center",
            cursor: !is_answer && !is_preview ? "grab" : 'default',
            justifyContent: "center",
            margin: "10px",
            minHeight: "50px",
            minWidth: "50px",
            background: color_light,
            borderWidth: "1px",
            borderStyle: "solid",
            borderColor: color_dark,
            borderRadius: "4px",
            zIndex: being_dragged ? 1 : "unset",
            ...get(answer_group, 'draggable_style'),
            ...extra_style
        }
    }

    function renderOption() {
        return (
            <div
                ref={setNodeRef}
                className="dnd-option"
                style={getDraggableStyle()}
                {...listeners}
                {...attributes}
            >
                <AnswerOptionContent html={option.html}/>

                <div className="dnd-option-layer"/>
            </div>
        );
    }

    function renderAnswer() {
        const showRemoveIcon = () => {
            if (!is_preview) {
                return (
                    <i
                        className="dnd-answer-one-remove far fa-times-circle"
                        onClick={onRemove}
                    >
                    </i>
                );
            }
        }

        return (
            <div
                className="dnd-answer-one"
                style={getDraggableStyle({})}
            >
                {showRemoveIcon()}

                <AnswerOptionContent html={option.html}/>
            </div>
        );
    }

    if (is_answer) {
        return renderAnswer();
    } else {
        return renderOption();
    }
}

AnswerOption.propTypes = {
    // is_answer = ture if this is for answer, is_answer = false for option
    is_answer: PropTypes.bool.isRequired,
    is_preview: PropTypes.bool.isRequired,
    answer_group: PropTypes.shape({
        dnd_type: PropTypes.string.isRequired,
        color_light: PropTypes.string,
        color_dark: PropTypes.string,
    }).isRequired,
    option: PropTypes.shape({
        dnd_id: PropTypes.string,
        html: PropTypes.string.isRequired,
        text: PropTypes.string.isRequired
    }).isRequired,
    extra_style: PropTypes.object,
    onRemove: PropTypes.func,
    being_dragged: PropTypes.bool.isRequired
}

export default AnswerOption

const AnswerOptionContent = memo(function ({html}) {
    return processStringContent(html);
});
