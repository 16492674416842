import React from 'react';
import PropTypes from 'prop-types';
import {get} from 'lodash';

import DnDDraggableItem from './DnDDraggableItem';


function AnswerOptions({component_data, is_preview}) {
    function getDroppableStyle() {
        return {
            display: "flex",
            flexWrap: "wrap",
            alignItems: "center",
            // justifyContent: "center",
            minWidth: "100px",
            minHeight: "100px",
            padding: "0",
            background: "transparent",
            ...get(component_data, 'data.option_droppable_style')
        }
    }

    function renderOptions() {
        const answerOptions = get(component_data, 'data.answer_options');

        return answerOptions.map((item, key) => {
            return (
                <div key={key}>
                    <DnDDraggableItem
                        is_preview={is_preview}
                        item={item}
                    />
                </div>
            );
        });
    }

    return (
        <div style={getDroppableStyle()}>
            <div className="cc-dndpuzzle-answer-options">
                <div className="flex-row flex-box-10-10">
                    {renderOptions()}
                </div>
            </div>
        </div>
    );
}

AnswerOptions.propTypes = {
    component_data: PropTypes.object.isRequired,
    is_preview: PropTypes.bool.isRequired,
}

export default AnswerOptions