import React, {forwardRef, useImperativeHandle, useState} from 'react';
import PropTypes from "prop-types";
import {
    DndContext,
    rectIntersection,
    MouseSensor,
    TouchSensor,
    PointerSensor,
    useSensor,
    useSensors
} from "@dnd-kit/core";
import {get, find, cloneDeep, remove, orderBy, isEqual} from 'lodash';

import AnswerOptions from './AnswerOptions';
import DnDDroppableItem from './DnDDroppableItem';

function DragNDropPuzzle(
    {
        component_data,
        is_preview,
        existing_answer,
        question_id
    },
    ref
) {
    const [answer, setAnswer] = useState(get(existing_answer, 'data.answer', defaultAnswer()));

    const sensors = useSensors(
        useSensor(PointerSensor, {
            activationConstraint: {
                distance: 10,
                delay: 100,
                tolerance: 5
            }
        }),
        useSensor(MouseSensor, {
            activationConstraint: {
                distance: 10,
                delay: 100,
                tolerance: 5
            }
        }),
        useSensor(TouchSensor, {
            activationConstraint: {
                delay: 250,
                tolerance: 5,
            }
        })
    );

    useImperativeHandle(ref, () => {
        return {
            isValid: function () {
                return answer.answer_selections.length > 0;
            },
            isEdited: function () {
                return !isEqual(get(existing_answer, 'data.answer', defaultAnswer()), answer);
            },
            isCorrect: function () {
                return true
            },
            getAnswer: function () {
                return answer;
            },
            props: {
                component_data,
                question_id
            }
        };
    }, [answer, existing_answer, component_data]);

    function defaultAnswer() {
        return {
            text: '',
            answer_selections: []
        }
    }

    function getAnswerText(answer_selections) {
        const answer_options = get(component_data, 'data.answer_options');
        const answer_layout = get(component_data, 'data.answer_layout');
        const answerTextArray = answer_selections.map(([layoutId, optionId]) => {
            const optionItem = find(answer_options, {dnd_id: optionId});
            const layoutItem = find(answer_layout, {dnd_id: layoutId});
            return get(layoutItem, 'content.text') + get(optionItem, 'content.text');
        });

        return answerTextArray.join('\n');
    }

    function onDragStart({active}) {
    }

    function onDragEnd({active, over}) {
        const draggable_id = get(active, 'id');
        const dest_droppable_id = get(over, 'id');
        const source_droppable_id = get(active, 'data.current.type');

        if (!draggable_id || !dest_droppable_id) {
            return null;
        }

        if (dest_droppable_id === source_droppable_id) {
            return null;
        }

        const answer_clone = cloneDeep(answer);

        // Moved answer back to options area
        if (dest_droppable_id === 'option-droppable') {
            return false;
        }

        // Moved answer from options area to answer area
        remove(answer_clone.answer_selections, (pair) => pair[0] === dest_droppable_id);
        answer_clone.answer_selections.push([dest_droppable_id, draggable_id]);
        answer_clone.answer_selections = orderBy(answer_clone.answer_selections, ([layoutId, optionId]) => layoutId);
        answer_clone.text = getAnswerText(answer_clone.answer_selections);

        setAnswer(answer_clone);
    }

    const onRemove = (droppable_dnd_id) => (draggable_dnd_id) => () => {
        const answer_clone = cloneDeep(answer);
        remove(answer_clone.answer_selections, (pair) => pair[0] === droppable_dnd_id && pair[1] === draggable_dnd_id);
        answer_clone.text = getAnswerText(answer_clone.answer_selections);
        setAnswer(answer_clone);
    }

    function getDnDContainerStyle() {
        const bgImg = get(component_data, 'data.background_img');
        const width = get(component_data, 'config.width');
        const height = get(component_data, 'config.height');

        return {
            backgroundImage: `url(${bgImg})`,
            backgroundRepeat: 'no-repeat',
            width,
            height
        }
    }

    function findOptionData(answerLayoutId) {
        const pair = find(answer.answer_selections, (pair) => pair[0] === answerLayoutId);
        const option_dnd_id = get(pair, 1);
        if (!option_dnd_id) {
            return undefined;
        }
        const answer_options = get(component_data, 'data.answer_options');
        return find(answer_options, {dnd_id: option_dnd_id});
    }

    function renderLayout() {
        const answerLayout = get(component_data, 'data.answer_layout');
        const renderLayoutItems = answerLayout.map((item, key) => {
            switch (item.type) {
                case 'dnd':
                    return (
                        <DnDDroppableItem
                            key={key}
                            component_data={component_data}
                            is_preview={is_preview}
                            item={item}
                            selected={findOptionData(item.dnd_id)}
                            onRemove={onRemove(item.dnd_id)}
                        />
                    );
                case 'static':
                default:
                    return null;
            }
        });

        return (
            <div className="cc-dndpuzzle-layout">
                {renderLayoutItems}
            </div>
        );
    }

    return (
        <div className="challenge-component-dndpuzzle">
            <DndContext
                onDragStart={onDragStart}
                onDragEnd={onDragEnd}
                sensors={sensors}
                collisionDetection={rectIntersection}
            >
                <AnswerOptions
                    component_data={component_data}
                    is_preview={is_preview}
                />

                <div className="cc-dndpuzzle-scroll">
                    <div
                        className="cc-dndpuzzle-bg"
                        style={getDnDContainerStyle()}
                    >
                        {renderLayout()}
                    </div>
                </div>
            </DndContext>
        </div>
    );
}

DragNDropPuzzle.propTypes = {
    component_data: PropTypes.shape({
        data: PropTypes.shape({
            answer_groups: PropTypes.arrayOf(PropTypes.shape({
                dnd_type: PropTypes.string.isRequired,
                direction: PropTypes.string.isRequired,
                options: PropTypes.arrayOf(PropTypes.shape({
                    dnd_id: PropTypes.string.isRequired,
                    html: PropTypes.string.isRequired,
                    text: PropTypes.string.isRequired
                })).isRequired,
                color_light: PropTypes.string,
                color_dark: PropTypes.string,
                // droppable_style: PropTypes.object,
                // draggable_style: PropTypes.object,
            })).isRequired,
            answer_layout: PropTypes.arrayOf(PropTypes.shape({
                type: PropTypes.string.isRequired,
                dnd_type: PropTypes.string,
                dnd_id: PropTypes.string,
                placeholder: PropTypes.string,
                // droppable_style: PropTypes.object,
                content: PropTypes.shape({
                    html: PropTypes.string.isRequired,
                    text: PropTypes.string.isRequired
                })
            })).isRequired
        }).isRequired
    }).isRequired,
    is_preview: PropTypes.bool,
    existing_answer: PropTypes.object,
    question_id: PropTypes.number
}

export default forwardRef(DragNDropPuzzle);
