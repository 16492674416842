import React from 'react';
import PropTypes from 'prop-types';
import {useDroppable} from '@dnd-kit/core';
import {get} from 'lodash';

import {processStringContent} from 'utils/content';
import DnDDraggedItem from './DnDDraggedItem';


function DnDDroppableItem({is_preview, item, selected, onRemove, component_data}) {
    const {isOver, setNodeRef} = useDroppable({
        id: item.dnd_id,
        disabled: is_preview
    });

    function getItemStyle() {
        return {
            ...item.item_position
        }
    }

    function getDroppableStyle() {
        const style = {
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            minWidth: "72px",
            minHeight: "72px",
            padding: isOver ? "10px" : "10px",
            borderRadius: "4px",
            position: "relative",
            ...get(item, 'droppable_style')
        }

        const hide_box_when_filled = get(component_data, 'config.hide_box_when_filled');

        if (selected && hide_box_when_filled) {
            style.border = "none";
            style.background = "none";
        }

        return style;
    }

    function renderSelected() {
        if (!selected) {
            if (item.placeholder) {
                return (
                    <div>
                        {processStringContent(item.placeholder)}
                    </div>
                );
            } else {
                return undefined;
            }
        }

        return (
            <DnDDraggedItem
                is_preview={is_preview}
                item={selected}
                onRemove={onRemove(selected.dnd_id)}
            />
        );
    }

    return (
        <div ref={setNodeRef} className="cc-dndpuzzle-layout-item" style={getItemStyle()}>
            <div style={getDroppableStyle()}>
                {renderSelected()}
            </div>
        </div>
    );
}

DnDDroppableItem.propTypes = {
    is_preview: PropTypes.bool.isRequired,
    item: PropTypes.shape({
        dnd_id: PropTypes.string.isRequired,
        placeholder: PropTypes.string
    }).isRequired,
    component_data: PropTypes.object.isRequired,
    selected: PropTypes.object,
    onRemove: PropTypes.func.isRequired
}

export default DnDDroppableItem
