import React from 'react';
import PropTypes from 'prop-types';

import {processStringContent} from 'utils/content';
import AnswerLayoutDroppable from "./AnswerLayoutDroppable";


function AnswerLayout({is_preview, answer_layout, answer_groups, onRemove}) {
    function renderLayout() {
        const render_layout = answer_layout.map((part, i) => {
            switch (part.type) {
                case 'hidden':
                    return null;
                case 'static':
                    return (
                        <div key={i} className="flex-row flex-vh-center">
                            {processStringContent(part.content.html)}
                        </div>
                    );
                case 'dnd':
                    return (
                        <div key={i}>
                            <AnswerLayoutDroppable
                                answer_groups={answer_groups}
                                onRemove={onRemove}
                                is_preview={is_preview}
                                part={part}
                            />
                        </div>
                    );
                default:
                    throw 'Unknown type';
            }
        });

        return (
            <div className="flex-row flex-box-10-10 flex-wrap">
                {render_layout}
            </div>
        )
    }

    return (
        <div className="component-dnd-answer">
            {renderLayout()}
        </div>
    );
}

AnswerLayout.propTypes = {
    is_preview: PropTypes.bool.isRequired,
    answer_layout: PropTypes.array.isRequired,
    answer_groups: PropTypes.array.isRequired,
    onRemove: PropTypes.func.isRequired
}

export default AnswerLayout
