import React, {useState, forwardRef, useImperativeHandle} from 'react';
import PropTypes from "prop-types";
import {
    DndContext,
    rectIntersection,
    MouseSensor,
    TouchSensor,
    PointerSensor,
    useSensor,
    useSensors
} from "@dnd-kit/core";
import {get, find, cloneDeep, isEmpty, isEqual} from 'lodash';

import AnswerGroup from './AnswerGroup';
import AnswerLayout from './AnswerLayout';

function DragNDrop(
    {
        component_data,
        is_preview,
        existing_answer,
        question_id
    },
    ref
) {
    const [answer, setAnswer] = useState(get(existing_answer, 'data.answer', defaultAnswer()));
    const [dragged_item, setDraggedItem] = useState(null);

    useImperativeHandle(ref, () => {
        return {
            isValid: function () {
                return !!find(answer.answer_layout, (al) => {
                    return al.type === 'dnd' && !isEmpty(al.content);
                });
            },
            isEdited: function () {
                return !isEqual(get(existing_answer, 'data.answer', defaultAnswer()), answer);
            },
            isCorrect: function () {
                if (get(component_data, 'config.auto_grading')) {
                    return isAnswerCorrect();
                } else {
                    return true
                }
            },
            getAnswer: function () {
                return answer;
            },
            props: {
                component_data,
                question_id
            }
        };
    }, [answer, existing_answer, component_data]);

    function defaultAnswer() {
        const answer_layout = get(component_data, 'data.answer_layout')
        return {
            text: getAnswerText(answer_layout),
            answer_layout
        }
    }

    function getAnswerText(answer_layout) {
        const is_not_empty = !!find(answer_layout, (al) => {
            return al.type === 'dnd' && !isEmpty(get(al, 'content.text'))
        })
        if (is_not_empty) {
            const text_array = answer_layout.map((al) => get(al, 'content.text', ' [unfilled] '))
            return text_array.join('')
        } else {
            return ""
        }
    }

    function isAnswerCorrect() {
        if (get(component_data, 'config.auto_grading')) {
            return !isEmpty(answer) && isEqual(answer, correctAnswer())
        } else {
            return true
        }
    }

    function correctAnswer() {
        return get(component_data, 'config.answer')
    }

    const sensors = useSensors(
        useSensor(PointerSensor, {
            activationConstraint: {
                distance: 10,
                delay: 100,
                tolerance: 5
            }
        }),
        useSensor(MouseSensor, {
            activationConstraint: {
                distance: 10,
                delay: 100,
                tolerance: 5
            }
        }),
        useSensor(TouchSensor, {
            activationConstraint: {
                delay: 250,
                tolerance: 5,
            }
        })
    );

    function onDragStart({active}) {
        setDraggedItem(active.id);
    }

    function onDragEnd({active, over}) {
        const draggable_id = get(active, 'id');
        const dest_droppable_id = get(over, 'id');
        const source_droppable_id = get(active, 'data.current.type');
        const dnd_type = get(active, 'data.current.type');

        if (!draggable_id || !dest_droppable_id) {
            return null
        }

        if (dest_droppable_id === source_droppable_id) {
            return null
        }

        const answer_clone = cloneDeep(answer)

        const source_droppable = find(answer_clone.answer_layout, {type: 'dnd', dnd_type, dnd_id: source_droppable_id})
        if (source_droppable) {
            // This is when user dragging an item out from an answer layout
            source_droppable.content = undefined
        }

        const dest_droppable = find(answer_clone.answer_layout, {type: 'dnd', dnd_type, dnd_id: dest_droppable_id})
        if (dest_droppable) {
            // This is when user dragging an item into an answer layout
            const answer_groups = get(component_data, 'data.answer_groups')
            const answer_group = find(answer_groups, {dnd_type})
            const draggable_item = find(answer_group.options, {dnd_id: draggable_id})
            const clone_draggable_item = cloneDeep(draggable_item)
            clone_draggable_item.dnd_id = undefined
            dest_droppable.content = clone_draggable_item
        }

        answer_clone.text = getAnswerText(answer_clone.answer_layout)

        setAnswer(answer_clone);
        setDraggedItem(null);
    }

    function onRemove(dnd_id) {
        const answer_clone = cloneDeep(answer)
        const answer_group = find(answer_clone.answer_layout, {dnd_id})
        answer_group.content = undefined
        answer_clone.text = getAnswerText(answer_clone.answer_layout)
        setAnswer(answer_clone);
    }

    function renderAnswerGroups() {
        return (
            <div className="flex-row flex-box-10-10 flex-wrap">
                {component_data.data.answer_groups.map((answer_group) => {
                    return (
                        <div key={answer_group.dnd_type}>
                            <AnswerGroup
                                is_preview={is_preview}
                                answer_group={answer_group}
                                answer_layout={get(answer, 'answer_layout')}
                                dragged_item={dragged_item}
                            />
                        </div>
                    );
                })}
            </div>
        );
    }

    function renderAnswerLayout() {
        const answer_layout = get(answer, 'answer_layout');
        const answer_groups = get(component_data, 'data.answer_groups');

        return (
            <AnswerLayout
                is_preview={is_preview}
                answer_layout={answer_layout}
                answer_groups={answer_groups}
                onRemove={onRemove}
            />
        );
    }

    return (
        <div className="challenge-component-dnd">
            <DndContext
                onDragStart={onDragStart}
                onDragEnd={onDragEnd}
                sensors={sensors}
                collisionDetection={rectIntersection}
            >
                {renderAnswerGroups()}

                <div className="section"/>

                {renderAnswerLayout()}
            </DndContext>
        </div>
    );
}

DragNDrop.propTypes = {
    component_data: PropTypes.shape({
        data: PropTypes.shape({
            answer_groups: PropTypes.arrayOf(PropTypes.shape({
                dnd_type: PropTypes.string.isRequired,
                direction: PropTypes.string.isRequired,
                options: PropTypes.arrayOf(PropTypes.shape({
                    dnd_id: PropTypes.string.isRequired,
                    html: PropTypes.string.isRequired,
                    text: PropTypes.string.isRequired
                })).isRequired,
                color_light: PropTypes.string,
                color_dark: PropTypes.string,
                // droppable_style: PropTypes.object,
                // draggable_style: PropTypes.object,
            })).isRequired,
            answer_layout: PropTypes.arrayOf(PropTypes.shape({
                type: PropTypes.string.isRequired,
                dnd_type: PropTypes.string,
                dnd_id: PropTypes.string,
                placeholder: PropTypes.string,
                // droppable_style: PropTypes.object,
                content: PropTypes.shape({
                    html: PropTypes.string.isRequired,
                    text: PropTypes.string.isRequired
                })
            })).isRequired
        }).isRequired
    }).isRequired,
    is_preview: PropTypes.bool,
    existing_answer: PropTypes.object,
    question_id: PropTypes.number
}

export default forwardRef(DragNDrop);
