import React from 'react';
import PropTypes from 'prop-types';
import {useDroppable} from "@dnd-kit/core";
import {get, find, filter} from 'lodash';

import AnswerOption from './AnswerOption';

function AnswerGroup({is_preview, answer_group, answer_layout, dragged_item}) {
    const {isOver, setNodeRef} = useDroppable({
        id: answer_group.dnd_type,
    });

    function getDroppableStyle() {
        const direction = get(answer_group, 'direction')
        const color_light = get(answer_group, 'color_light')
        const color_dark = get(answer_group, 'color_dark')
        return {
            //color: isOver ? 'green' : undefined,//TODO example only
            display: "flex",
            flexDirection: (direction === 'vertical' ? "column" : "row"),
            flexWrap: "wrap",
            alignItems: "center",
            // justifyContent: "center",
            minWidth: "100px",
            minHeight: "100px",
            padding: "10px",
            background: "#FFFFFF",
            borderWidth: "2px",
            borderStyle: "solid",
            borderColor: color_light,
            borderRadius: "4px",
            ...get(answer_group, 'droppable_style')
        }
    }

    function renderOptions() {
        const not_selected_options = filter(answer_group.options, ((option) => {
            return !find(answer_layout, (al) => {
                return get(al, 'content.dnd_id') === option.dnd_id
            })
        }));
        return not_selected_options.map((option, i) => {
            return (
                <AnswerOption
                    key={option.dnd_id}
                    is_answer={false}
                    is_preview={is_preview}
                    answer_group={answer_group}
                    option={option}
                    option_index={i}
                    being_dragged={dragged_item === option.dnd_id}
                />
            );
        });
    }

    return (
        <div ref={setNodeRef} style={getDroppableStyle()}>
            {renderOptions()}
        </div>
    );
}

AnswerGroup.propTypes = {
    is_preview: PropTypes.bool.isRequired,
    answer_group: PropTypes.object.isRequired,
    answer_layout: PropTypes.array.isRequired,
    dragged_item: PropTypes.string
}

export default AnswerGroup
